import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";
// 정산서 관리 문서함 공통
const StatementListMixin = {
  mixins: [commDocs],
  methods: {
    /**
     * 문서함 필터 연동?
     * @param formData
     */
    selectConnector(formData) {
      this.searchForm = formData;
      localStorage.searchForm = JSON.stringify(this.searchForm);
      this.$refs.docList.resetSort(1); // 서칭시 페이지 초기화로 reset
    },
    /**
     * 문서함 필터 초기화?
     * @param formData
     */
    initSearchForm(formData) {
      this.searchForm = formData;
    },
    /**
     * 문서함 데이터 가져오기
     * @param pagingConfig
     * @param sort
     * @return {Promise<void>}
     */
    async getDocList(pagingConfig, sort) {
      const DEFAULT_PAGE_CURRENT = 1; // 현재 페이지 기본값
      const DEFAULT_PAGE_RANGE = 10; // 페이지 행 수 기본값
      const REGEXP_DATE = /-/gi; // 날짜 형식 변경 조건
      const DATE_REPLACE_VAL = ""; // 날짜 형식 변경 값

      const queryOpt = {
        // 대상 구분
        targetGubun: this.targetGubun || null,
        // 결재 문서 상태
        docStatus: this.docStatus || null,
        // 결재자 진행 상태
        aprvlStatus: this.aprvlStatus || null,
        // 기안 시작 날짜
        strDate: this.searchForm.draftFromDate
          ? this.searchForm.draftFromDate.replace(REGEXP_DATE, DATE_REPLACE_VAL)
          : null,
        // 기안 종료 날짜
        endDate: this.searchForm.draftToDate
          ? this.searchForm.draftToDate.replace(REGEXP_DATE, DATE_REPLACE_VAL)
          : null,
        // 승인 시작 날짜
        compStrDate: this.searchForm.completeFromDate
          ? this.searchForm.completeFromDate.replace(REGEXP_DATE, DATE_REPLACE_VAL)
          : null,
        // 승인 종료 날짜
        compEndDate: this.searchForm.completeToDate
          ? this.searchForm.completeToDate.replace(REGEXP_DATE, DATE_REPLACE_VAL)
          : null,
        // 결재 선 계정 번호
        aprvlLineAccntNo: this.searchForm.payAccountId || null,
        // 검색 유형(구매요청/정산서 번호 : docCid, 제목검색: title, 구매요청자 : drft, 결재대기자: nextAprvl)
        searchType: this.searchForm.searchKeyword ? this.searchForm.searchType.code : null,
        // 검색 값
        searchValue: this.searchForm.searchKeyword ? this.searchForm.searchKeyword : null,
        // 요청 페이지
        page:
          pagingConfig && pagingConfig.pageCurrent
            ? pagingConfig.pageCurrent
            : DEFAULT_PAGE_CURRENT,
        // 페이지 출력 수
        size: pagingConfig && pagingConfig.pageRange ? pagingConfig.pageRange : DEFAULT_PAGE_RANGE,
      };

      let queryTxt = "";
      let hasQueryTxt = false;
      for (let i = 0; i < Object.keys(queryOpt).length; i++) {
        if (i === 0) queryTxt += "?";

        if (Object.values(queryOpt)[i]) {
          if (hasQueryTxt) queryTxt += "&";
          queryTxt += `${Object.keys(queryOpt)[i]}=${Object.values(queryOpt)[i]}`;
          if (!hasQueryTxt) hasQueryTxt = true;
        }
      }

      let path = `${this.$apiPath.APRVL_DOC_LIST}${queryTxt}`;

      try {
        const res = await ApiService.shared.getData(path);

        if (res.code === "200") {
          this.totalPage = parseInt(res.total) || 1;
          // if (res.data && res.data.length > 0) {
          //   res.data.forEach(e => e.drftDate = this.strDateTimeSplit(e.drftDate));
          //   res.data.forEach(e => e.docStatusDate = this.strDateTimeSplit(e.docStatusDate));
          // }
          this.docList = res.data || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * 상세페이지로 이동
     * @param docNo
     */
    goDetail(docNo) {
      if (!this.detailViewName || !docNo) return;
      this.$router.push({
        name: this.detailViewName,
        query: { docNo: docNo },
      });
    },
  },
};

export default StatementListMixin;
